// custom typefaces
import "@fontsource-variable/montserrat"
import "@fontsource/merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// bootstrap CSS style
import 'bootstrap/dist/css/bootstrap.min.css';
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  window.history.scrollRestoration = 'manual'
  const currentPosition = getSavedScrollPosition(location) || [0, 0]

  setTimeout(() => {
    if (location.hash) {
      const item = document.querySelector(`${location.hash}`)
      item?.scrollIntoView({ behavior: 'instant' })
    } else {
      window.scrollTo({
        top: currentPosition[1],
        left: currentPosition[0],
        behavior: 'instant',
      })
    }
  }, 0)

  return false
}
